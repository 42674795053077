import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/store/modules/spinnerModule";
import Snackbar from "@/store/modules/errorSnackbar";
import { ApiSistemaPagos, ApiErrorResponse, AxiosHttpClient } from "apd.apiconnectors";
import { getUrl, ApiList } from "@/config/apiUrls";
import JsonExcel from "vue-json-excel";
import { Filtro, EstatusOperacion, OperacionExtendida } from "apd.models";
import { TableHeader } from "@/models/vuetifyModels";
import pagosForm from "@/components/forms/pagosForm.vue";
import {
  mdiTableSearch,
  mdiCloseBox,
  mdiMinusBox,
  mdiCheckboxBlankOutline,
  mdiCalendarExport,
  mdiClose,
} from "@mdi/js";
import { format } from "date-fns";
import { ToDate } from "@/filters";
import { Rules } from "@/models/rules";

Vue.component("DownloadExcel", JsonExcel);

@Component({
  components: {
    pagosForm,
  },
  filters: {
    toMoney(value: number): string {
      if (typeof value !== "number") {
        return value;
      }
      return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    toDate(value: string): string {
      if (value) {
        return format(Date.parse(value), "MM/dd/yy HH:mm:ss");
      } else {
        return "";
      }
    },
  },
})
export default class ReportePagosComponent extends Vue {
  public IconTSearch: string = mdiTableSearch;
  public IconCloseCh: string = mdiCloseBox;
  public IconClose: string = mdiClose;
  public IconMinusCh: string = mdiMinusBox;
  public IconCheckbox: string = mdiCheckboxBlankOutline;
  public IconExport: string = mdiCalendarExport;
  public user = this.$store.getters["oidcStore/oidcUser"];

  public tipoPago = "Pagos";
  public dialog = false;
  public tzoffset = new Date().getTimezoneOffset() * 60000;
  public date = new Date(Date.now() - this.tzoffset).toISOString().substr(0, 10);
  public date2 = new Date(Date.now() - this.tzoffset).toISOString().substr(0, 10);
  public menu = false;
  public menu2 = false;
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };

  public headers: TableHeader[] = [
    {
      text: "Fecha",
      value: "fecha",
      align: "center",
      sortable: true,
    },
    {
      text: "Cajero",
      value: "cajero",
      align: "center",
      sortable: true,
    },
    {
      text: "Servicio",
      value: "servicio",
      align: "center",
      sortable: true,
    },
    {
      text: "Referencia",
      value: "referencia",
      align: "center",
      sortable: false,
    },
    {
      text: "Importe",
      value: "importe",
      align: "center",
      sortable: true,
    },
    {
      text: "Folio PD",
      value: "folioPD",
      align: "center",
      sortable: false,
    },
    {
      text: "Folio Servicio",
      value: "folioServicio",
      align: "center",
      sortable: false,
    },
    {
      text: "Forma de Pago",
      value: "formaPago",
      align: "center",
      sortable: false,
    },
    {
      text: "Tipo de Tarjeta",
      value: "tarjeta",
      align: "center",
      sortable: true,
    },
    {
      text: "Estatus",
      value: "estatus",
      align: "center",
      sortable: true,
    },
  ];
  public json_meta = [
    [
      {
        key: "charset",
        value: "utf-8",
      },
    ],
  ];
  public json_fields = {
    Fecha: {
      callback: (value: OperacionExtendida): string | undefined => {
        const res = this.fecha(value);
        return ToDate(res);
      },
    },
    Cajero: "nombreKiosko",
    Servicio: "nombreServicio",
    Referencia: {
      field: "referenciaPago",
      callback: (value: string): string => {
        return value.toString();
      },
    },
    Importe: {
      field: "importeServicio",
      callback: (value: number): string => {
        return "$" + value.toFixed(2);
      },
    },
    FolioPD: "folioAPD",
    FolioServicio: "folioServicio",
    FormaPago: "formaPago",
    TipoTarjeta: "nombreCuenta",
    Estatus: {
      field: "estatusOperacion",
      callback: (value: EstatusOperacion): string => {
        return this.estatus(value);
      },
    },
  };

  public estatusList = [
    { value: 3, nombre: "Aplicado" },
    { value: 2, nombre: "Cobrado" },
    { value: 5, nombre: "Cancelado" },
    { value: 10, nombre: "Sospecha de fraude" },
    { value: 0, nombre: "Intento Pago" },
    { value: 11, nombre: "Revisado" },
    { value: 6, nombre: "Reembolsado" },
    { value: 8, nombre: "AplicadaIncompleta" },
    { value: 12, nombre: "CobradaIncompleta" },
  ];

  public estatus(item: EstatusOperacion): string {
    return EstatusOperacion[item];
  }

  public formData = [] as OperacionExtendida[];

  public get loading(): boolean {
    return Spinner.showLoader;
  }

  public get rules(): Rules {
    return this.$store.state.validationRules;
  }

  public get reporteNombre(): string {
    return "reportePagos" + this.date + "/" + this.date2 + ".xls";
  }

  public fecha(item: OperacionExtendida): string | undefined {
    if (
      item.estatusOperacion == EstatusOperacion.Aplicada ||
      item.estatusOperacion == EstatusOperacion.AplicadaIncompleta
    ) {
      return item.fechaAplicacion;
    } else {
      return (item.fechaAplicacion = item.created);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async buscarReporte(formFiltro: Filtro) {
    Spinner.Show();

    if (formFiltro.fechaInicial) {
      this.date = formFiltro.fechaInicial;
    }

    if (formFiltro.fechaFinal) {
      this.date2 = formFiltro.fechaFinal;
    }

    if (formFiltro.idFormaPago == "001") {
      formFiltro.idFormaPago = undefined;
    }

    await this.clienteSP()
      .GetOperacionesFormaPago(formFiltro)
      .then((resp) => {
        //TODO: Crear propiedad TipoTarjeta en clase OperacionExtendida
        this.formData = resp as unknown as OperacionExtendida[];
        this.dialog = true;
        Spinner.Hide();
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        this.dialog = false;
        Snackbar.Show(err.apiError.mensajeUsuario);
        Spinner.Hide();
      });
  }

  public totalPagos(): number {
    return this.formData.reduce((r) => r + 1, 0);
  }

  public totalImportes(): number {
    if (this.tipoPago == "Pagos") {
      return this.formData.reduce((r, i) => r + Number(i.importeServicio) + Number(i.importeComision), 0);
    } else {
      return this.formData.reduce((r, i) => r + Number(i.importeComision) + Number(i.impuestoComision), 0);
    }
  }

  public fp(item: string): string {
    if (item.includes("Tarjeta")) {
      return "Tarjeta";
    } else {
      return item;
    }
  }

  public fetchExcelData(): unknown {
    return this.formData;
  }

  public filter(item: OperacionExtendida): string {
    if (item.nombreServicio.startsWith("Ecogas")) return item.nombreCuenta;
    else return "";
  }
}
