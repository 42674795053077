import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/store/modules/spinnerModule";
import Snackbar from "@/store/modules/errorSnackbar";
import Dropdowns from "@/store/modules/dropdownModule";
import { ApiSistemaPagos, ApiErrorResponse, AxiosHttpClient } from "apd.apiconnectors";
import { getUrl, ApiList } from "@/config/apiUrls";
import { mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline, mdiTableSearch } from "@mdi/js";
import { Rules } from "@/models/rules";
import { Municipio, Filtro, FormaPago, EstatusOperacion, Servicio, Empresa, Kiosko } from "apd.models";

@Component({
  props: {
    empresasVisibility: {
      type: Boolean,
      default: true,
    },
    serviciosVisibility: {
      type: Boolean,
      default: true,
    },
    estatusVisibility: {
      type: Boolean,
      default: false,
    },
    name: String,
    onClickFunc: {
      type: Function,
      required: true,
    },
    filtroCajeroVisibility: {
      type: Boolean,
      default: false,
    },
  },
})
export default class ReportePagosComponent extends Vue {
  public IconCloseCh: string = mdiCloseBox;
  public IconMinusCh: string = mdiMinusBox;
  public IconCheckbox: string = mdiCheckboxBlankOutline;
  public IconTSearch: string = mdiTableSearch;
  public user = this.$store.getters["oidcStore/oidcUser"];
  public nombreReporte = this.$props.name;
  public visualizarEstatus = this.$props.estatusVisibility;
  public visualizarServicios = this.$props.serviciosVisibility;
  public visualizarEmpresas = this.$props.empresasVisibility;
  public filtroCajeroVisibility = this.$props.filtroCajeroVisibility;
  public filtrarKioskos = true;

  public loadCajero = false;
  public tzoffset = new Date().getTimezoneOffset() * 60000;
  public date = new Date(Date.now() - this.tzoffset).toISOString().substr(0, 10);
  public date2 = new Date(Date.now() - this.tzoffset).toISOString().substr(0, 10);
  public clienteSP = (): ApiSistemaPagos => {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    return new ApiSistemaPagos(client);
  };

  public estatusList = [
    { value: 3, nombre: "Aplicado" },
    { value: 2, nombre: "Cobrado" },
    { value: 5, nombre: "Cancelado" },
    { value: 10, nombre: "Sospecha de fraude" },
    { value: 0, nombre: "Intento Pago" },
    { value: 11, nombre: "Revisado" },
    { value: 6, nombre: "Reembolsado" },
    { value: 8, nombre: "AplicadaIncompleta" },
    { value: 12, nombre: "CobradaIncompleta" },
  ];

  public estatus(item: EstatusOperacion): string {
    return EstatusOperacion[item];
  }

  public formFiltro: Filtro = {
    rfc: undefined,
    email: undefined,
    referenciaPago: undefined,
    folioAutorizacion: undefined,
    folioAPD: undefined,
    telefono: undefined,
    limitar: undefined,
    autorizado: undefined,
    idEmpresa: undefined,
    idFormaPago: undefined,
    municipios: [],
    estatusOperacion: [3],
    kioskos: [],
    servicios: [],
    fechaInicial: undefined,
    fechaFinal: undefined,
  };

  public serviciosArr = [] as Servicio[];
  public empresasArr = [] as Empresa[];
  public cajerosArr = [] as Kiosko[];
  public municipiosArr = [] as Municipio[];
  public formasPArr = [] as FormaPago[];

  public async mounted(): Promise<void> {
    Spinner.Show();
    this.LoadData()
      .then(() => Spinner.Hide())
      .catch(() => Spinner.Hide());
  }
  public get loading(): boolean {
    return Spinner.showLoader;
  }
  public get rules(): Rules {
    return this.$store.state.validationRules;
  }

  public formasPago(): void {
    const ob = {} as FormaPago;
    ob.id = "001";
    ob.nombre = "-Seleccionar Todos-";
    let value = [] as FormaPago[];
    this.clienteSP()
      .GetFormasPago()
      .then((resp) => {
        value = resp as FormaPago[];
        this.formasPArr = value.sort((a, b) => a.nombre.localeCompare(b.nombre));
        this.formasPArr.unshift(ob);
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        Snackbar.Show(err.apiError.mensajeUsuario);
        Spinner.Hide();
      });
  }

  public empresas(): void {
    const user = this.$store.getters["oidcStore/oidcUser"];
    if (user["user_type"] == "Empleado") {
      if ((Dropdowns.ListaEmpresas() as Empresa[]).length == 0) {
        Dropdowns.GetEmpresas()
          .then(() => {
            this.empresasArr = Dropdowns.ListaEmpresas() as Empresa[];
            this.empresasArr.sort((a, b) => a.nombre.localeCompare(b.nombre));
          })
          .catch((resp) => {
            const err: ApiErrorResponse = resp as ApiErrorResponse;
            Snackbar.Show(err.apiError.mensajeUsuario);
            Spinner.Hide();
          });
      } else {
        this.empresasArr = Dropdowns.ListaEmpresas() as Empresa[];
      }
    } else {
      this.clienteSP()
        .GetEmpresa(user["RSP.Empresa"])
        .then((resp) => {
          this.empresasArr.push(resp as Empresa);
          this.formFiltro.idEmpresa = this.empresasArr[0].id;
          this.servicios();
        })
        .catch((resp) => {
          const err: ApiErrorResponse = resp as ApiErrorResponse;
          Snackbar.Show(
            err.apiError.mensajeUsuario + " | Faltan configuraciones comuniquese con su proveedor del servicio.",
          );
          this.formFiltro.idEmpresa = "0000";
          Spinner.Hide();
        });
    }
  }

  public servicios(): void {
    const user = this.$store.getters["oidcStore/oidcUser"];
    let value = [] as Servicio[];
    this.formFiltro.servicios = [];
    if (this.formFiltro.idEmpresa != undefined) {
      this.clienteSP()
        .GetServiciosEmpresa(this.formFiltro.idEmpresa)
        .then((resp) => {
          value = resp as Servicio[];
          this.serviciosArr = value.sort((a, b) => a.nombreReportes.localeCompare(b.nombreReportes));
          if (user["user_type"] != "Empleado") {
            this.formFiltro.servicios?.push(this.serviciosArr[0].id);
          } else {
            this.serviciosArr.forEach((s) => {
              this.formFiltro.servicios?.push(s.id.toString());
            });
          }
        })
        .catch((resp) => {
          const err: ApiErrorResponse = resp as ApiErrorResponse;
          Snackbar.Show(err.apiError.mensajeUsuario);
          Spinner.Hide();
        });
    }
  }

  public cajeros(): void {
    this.loadCajero = true;
    this.formFiltro.kioskos = [];
    if (this.formFiltro.municipios) {
      Dropdowns.GetKioskosMunicipio(this.formFiltro.municipios).then(() => {
        this.cajerosArr = (Dropdowns.ListaKioskos() as Kiosko[]).sort((a, b) =>
          a.nombreReportes.localeCompare(b.nombreReportes),
        );
        if (this.filtrarKioskos) {
          this.cajerosArr = this.cajerosArr.filter(
            (o) =>
              o.mostrarReportes ||
              (o.ubicacion == "WEB" && !o.nombreReportes.startsWith("Caj") && !o.nombreReportes.endsWith("Ext")),
          );
        } else {
          this.cajerosArr = this.cajerosArr.filter(
            (o) =>
              o.ubicacion != "WEB" ||
              (o.ubicacion == "WEB" && !o.nombreReportes.startsWith("Caj") && !o.nombreReportes.endsWith("Ext")),
          );
        }
        const user = this.$store.getters["oidcStore/oidcUser"];
        if (user["user_type"] != "Empleado") {
          this.cajerosArr = this.cajerosArr.filter((c) => c.idEmpresa == user["RSP.Empresa"]);
          this.formFiltro.kioskos?.push(this.cajerosArr[0].id);
        } else {
          this.cajerosArr.forEach((c) => {
            this.formFiltro.kioskos?.push(c.id);
          });
        }
      });
    }
    this.loadCajero = false;
  }

  public async LoadData(): Promise<void> {
    Spinner.Show();
    try {
      this.formasPago();
      //this.cajeros();
      this.empresas();

      await Dropdowns.GetMunicipios().then(() => {
        this.municipiosArr = Dropdowns.ListaMunicipios() as Municipio[];
        this.municipiosArr.push({
          id: 0,
          idEstado: 0,
          nombre: "Internet",
          created: Date.now().toString(),
          updated: Date.now().toString(),
        });
        this.municipiosArr.sort((a, b) => a.nombre.localeCompare(b.nombre));
      });
    } catch (e) {
      const err = e as ApiErrorResponse;
      Snackbar.Show(err.apiError.mensajeUsuario);
    }
    Spinner.Hide();
  }

  // iconos kiosko multi-select vue
  public iconKioskos(): string {
    if (this.formFiltro.kioskos && this.formFiltro.kioskos?.length <= 0) {
      return this.IconMinusCh;
    }
    if (this.formFiltro.kioskos && this.formFiltro.kioskos?.length > 0) {
      return this.IconCheckbox;
    }
    return this.IconCloseCh;
  }

  public iconMunicipios(): string {
    if (this.formFiltro.municipios && this.formFiltro.municipios?.length <= 0) {
      return this.IconMinusCh;
    }
    if (this.formFiltro.municipios && this.formFiltro.municipios?.length > 0) {
      return this.IconCheckbox;
    }
    return this.IconCloseCh;
  }

  public toggleFiltro(): void {
    this.cajeros();
  }

  public toggleMunicipios(): void {
    if (this.formFiltro.municipios?.length === this.municipiosArr.length) {
      this.formFiltro.municipios = [];
    } else {
      this.formFiltro.municipios = this.municipiosArr.map((municipio) => {
        return municipio.id;
      });
    }
    this.cajeros();
  }

  public toggleKioskos(): void {
    if (this.formFiltro.kioskos?.length === this.cajerosArr.length) {
      this.formFiltro.kioskos = [];
    } else {
      this.formFiltro.kioskos = this.cajerosArr.map((cajero) => {
        return cajero.id;
      });
    }
  }

  public icon(): string {
    if (this.formFiltro.servicios != undefined) {
      if (this.formFiltro.servicios.length == 0) {
        return this.IconMinusCh;
      }
      if (this.formFiltro.servicios.length > 0) {
        return this.IconCheckbox;
      }
    }
    return this.IconCloseCh;
  }

  public toggle(): void {
    if (this.formFiltro.servicios?.length === this.serviciosArr.length) {
      this.formFiltro.servicios = [];
    } else {
      this.formFiltro.servicios = this.serviciosArr.map((service) => {
        return service.id;
      });
    }
  }

  public buscar(): void {
    this.formFiltro.fechaInicial = this.date;
    this.formFiltro.fechaFinal = this.date2 + " " + "23:59:59";
    this.$props.onClickFunc(this.formFiltro);
  }
}
